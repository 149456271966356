import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import { ApplicationLayout } from '../application';
import { navigate } from '@reach/router';
import { Row, Col, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Badge, Form, FormGroup, Input, Label, FormFeedback, FormText, Alert } from 'reactstrap'
import { handleSandboxDeleteApplication, handleAddApplicationDelegate, handleGetApplicationDelegate, handleDeleteApplicationDelegate, handleProductionDeleteApplication, handleGetApiDetails } from '../../../services/api';
import { PulseLoader, BarLoader } from 'react-spinners';
import { isLoggedIn } from "../../../services/auth";
import { MdAdd } from 'react-icons/md';

const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false

export default class view extends Component {
    constructor(props) {
        super(props)

        if (this.props.location.state === null || !this.props.location.state) {
            navigate('/account/application/', { state: {} })

            this.state = {
                data: null,
                stage: null,
                apis: null
            }
        } else {
            if (this.props.location.state.stage === 'sandbox') {
                this.state = {
                    data: this.props.location.state.data,
                    stage: this.props.location.state.stage,
                    apis: this.props.location.state.apis
                }
            } else if (this.props.location.state.stage === 'production') {
                this.state = {
                    data: this.props.location.state.data,
                    stage: this.props.location.state.stage,
                    apis: this.props.location.state.apis
                }
            } else if (this.props.location.state.stage === 'delegated') {
                this.state = {
                    data: this.props.location.state.data,
                    state: this.props.location.state.stage,
                    apis: this.props.location.state.apis
                }
            }
        }
    }

    async componentDidMount() {
        if (!this.props.location.state) {
            await navigate('/account/application/', { state: {} })
            this.forceUpdate()
        }

        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
    }

    UNSAFE_componentWillUpdate() {
        if (!this.props.location.state) {
            navigate('/account/application/', { state: {} })
        }
    }

    render() {
        if (!this.props.location.state) {
            if (typeof window !== "undefined") {
                window.location.reload()
            }
            return null
        } else {
            // Different view for Sandbox Application
            if (this.state.stage === 'sandbox') {
                return (
                    <ApplicationLayout location={this.props.location}>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }}>
                                <ViewSandboxContent data={this.state.data} stage={this.state.stage} apiData={this.state.apis} />
                            </Col>
                        </Row>
                    </ApplicationLayout>
                )
                // Different view for Production Application
            } else if (this.state.stage === 'production') {
                return (
                    <ApplicationLayout location={this.props.location}>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }}>
                                <ViewProductionContent data={this.state.data} stage="production" apiData={this.state.apis} />
                            </Col>
                        </Row>
                    </ApplicationLayout>
                )
            } else {
                return (
                    <ApplicationLayout location={this.props.location}>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }}>
                                <ViewSandboxContent data={this.state.data} stage={this.state.state} apiData={this.state.apis} />
                            </Col>
                        </Row>
                    </ApplicationLayout>
                )
            }
        }
    }
}

// Sandbox View
export class ViewSandboxContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: this.props.data,
            stage: this.props.stage,
            api: this.props.data.api,
            email: "",

            modal: false,
            delete: false,
            add: false,

            isDeleteLoading: false,
            isApiLoading: false,
            isQrLoading: false,
            isAddLoading: false,
            isDelegateLoading: false,

            error: false,
            name: user ? user.userData.attributes.name : "",
            selfEmail: user ? user.userData.attributes.email : "",
            username : user ? user.userData.username : "",
            addDelegateMessage: "",

            delegationNotification: false,
            apiList: this.props.apiData,
            apiStr: [],
            notifyType: ''
        }
        this.toggle = this.toggle.bind(this)
        this.delete = this.delete.bind(this)
        this.add = this.add.bind(this)
        this.onDismiss = this.onDismiss.bind(this)
    }

    async componentDidMount() {
        await handleGetApplicationDelegate(this.state)
            .then((res) => {
                this.setState({
                    delegates: res
                })
            })
            .catch(err => console.log(err))
    }

    async reinitializeDelegates() {
        await handleGetApplicationDelegate(this.state)
            .then((res) => {
                this.setState({
                    delegates: res
                })
            })
            .catch(err => console.log(err))
    }

    resetModalStates() {
        this.setState({
            addDelegateMessage: "",
            error: false,
            email: ""
        })
    }

    // MODAL
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    // MODAL
    delete() {
        this.setState({
            delete: !this.state.delete
        });
    }

    // MODAL
    add() {
        this.setState({
            add: !this.state.add
        })
    }

    onChange = event => {
        const name = event.target.id
        const value = event.target.value

        this.setState({
            [name]: value
        })
    }

    handleDelete = async (event) => {
        this.setState({
            isDeleteLoading: true
        })
        const appId = this.state.data._id
        const name = !user ? null : user.userData.username

        await handleSandboxDeleteApplication({ appId, name })
            .then((res) => {
                navigate('/account/application/', { state: { notification: "warning", message: "Application has been successfully deleted." } })
            })
            .catch(err => console.log(err))

    }

    addApi(e) {
        this.setState({
            apiStr: [...this.state.api, e],
        })
    }

    arrayRemove = (arr, value) => {

        return arr.filter(function (ele) {
            return ele !== value;
        });

    }

    submitDelegate = async (event) => {
        event.preventDefault()
        this.setState({
            isAddLoading: true
        })

        if (this.state.email === this.state.selfEmail) {
            this.setState({
                error: true,
                isAddLoading: false,
                addDelegateMessage: "Cannot add yourself as a delegate"
            })
        } else {
            await handleAddApplicationDelegate(this.state)
                .then((res) => {
                    if (res && res.valid !== false) {

                        this.setState({
                            isAddLoading: false,
                            add: false,
                            delegationNotification: true,
                            notifyType: 'Added'
                        })

                        this.reinitializeDelegates()
                    } else {
                        this.setState({
                            error: true,
                            isAddLoading: false,
                            addDelegateMessage: "The email address already exists in the delegated users' pool"
                        })
                    }
                })
                .catch(err => console.log(err))
        }
    }

    onDismiss() {
        this.setState({ delegationNotification: !this.state.delegationNotification });
    }

    deleteDelegate = async (event) => {
        event.preventDefault()
    }

    onRemoveDelegateSuccess() {
        this.setState({
            delegationNotification: true,
            notifyType: 'Removed'
        })
    }

    render() {
        return (
            <Row>
                <Col md={10}>
                    <h1>
                        <Badge color={this.state.stage === "sandbox" ? "warning" : this.state.stage === "close-loop" ? "danger" : this.state.stage === "delegated" ? "secondary" : "info"}>
                            {this.state.stage === "sandbox" ? "Sandbox" : this.state.stage === "close-loop" ? "Close Loop" : this.state.stage === "delegated" ? "Delegated" : "Production"}
                        </Badge><br />
                        {this.state.data.name}{` `}
                    </h1>
                    <p>

                    </p>
                </Col>
                {this.state.stage === 'delegated' ? null : (
                    <Col md={2}>
                        <Row>
                            <Button color="danger"
                                onClick={this.delete}>
                                Delete App
                            </Button>
                            <Modal
                                isOpen={this.state.delete}
                                toggle={this.delete}
                                className={this.props.className}
                            >
                                <ModalHeader toggle={this.delete}>Confirm Delete Application</ModalHeader>
                                <ModalBody>
                                    Are you sure you want to delete this application?<br />
                                    <i>Note : Delegation entries will also be deleted</i>
                                </ModalBody>
                                <ModalFooter>.
                                    <Button
                                        color="danger"
                                        onClick={this.handleDelete}
                                        disabled={this.state.isDeleteLoading}
                                    >
                                        {this.state.isDeleteLoading ? (
                                            <PulseLoader
                                                size={10}
                                                color="#fff"
                                            />
                                        ) : (
                                                `DELETE`
                                            )}
                                    </Button>{' '}
                                    <Button
                                        color="primary"
                                        onClick={this.delete}
                                    >
                                        Cancel
                                    </Button>{' '}
                                </ModalFooter>
                            </Modal>
                        </Row>
                    </Col>
                )}
                <Col md={12}>
                    &nbsp;
                </Col>
                <Col md={12}>
                    <Table borderless>
                        <tbody>
                            <tr>
                                <td style={{ padding: `1rem` }} colSpan={8}>
                                    <b>API Key</b>
                                </td>
                                <td style={{ textAlign: `left`, padding: `1rem` }} colSpan={1}>
                                    {this.state.data.key}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: `1rem` }} colSpan={8}>
                                    <b>Secret</b>
                                </td>
                                <td style={{ textAlign: `left`, padding: `1rem` }} colSpan={1}>
                                    <Button color="info"
                                        onClick={this.toggle}>
                                        Click here to view Secret
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: `1rem` }} colSpan={8}>
                                    <b>API(s) Enabled</b>
                                </td>
                                <td>
                                    {this.state.api.includes('/GW2/TxnQuery') || this.state.api.includes('cb02e4dd-7cc3-44eb-ba24-65831556c947') || this.state.api.includes('/GW2/TxnReqListener') ? `eNETS` : null}{this.state.data.api.length > 3 ? `,` : ` `}
                                    {this.state.api.includes('/merchantservices/qr/dynamic/v1/transaction/reversal') || this.state.api.includes('73b037f5-591c-4ec6-a701-a7ad93fb5962') || this.state.api.includes('/merchantservices/qr/dynamic/v1/order/request') || this.state.api.includes('/merchantservices/qr/dynamic/v1/transaction/query') ? `NETS QR` : null}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                        <ModalHeader toggle={this.toggle}>Secret</ModalHeader>
                        <ModalBody>
                            {this.state.data.secret}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={event => {

                                    this.toggle()
                                }}>
                                Close
                            </Button>{' '}
                        </ModalFooter>
                    </Modal>
                </Col>

                {/* DELEGATED USER */}
                {this.state.stage !== 'production' ? (
                    <Col md={12}>


                        <h2>
                            Delegated Users
                        </h2>
                        <Alert isOpen={this.state.delegationNotification} color="success" toggle={this.onDismiss}>
                            Delegation {this.state.notifyType}!
                        </Alert>
                        <Table>
                            <thead style={{ background: `#eee` }}>
                                <tr>
                                    <th style={{ padding: `1rem` }}>Date Added</th>
                                    <th style={{ padding: `1rem` }}>Email Address</th>
                                    {(this.state.stage !== 'delegated') ? (
                                        <th style={{ textAlign: `center`, padding: `1rem` }}>Actions</th>
                                    ) : <th>&nbsp;</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.isDelegateLoading ? (
                                    <div className="text-center" style={{ margin: `auto`, padding: `1.5rem 0` }}>
                                        {`Loading Delegates...`}
                                        <BarLoader
                                            sizeUnit={"px"}
                                            width={200}
                                            color={'#123abc'}
                                        />
                                    </div>
                                ) : (
                                        <DelegateContainer items={this.state.delegates} stage={this.state.stage} onSuccess={this.onRemoveDelegateSuccess.bind(this)} />
                                    )}

                                {(this.state.stage !== 'delegated') ? (
                                    <tr>

                                        <td colSpan={3} className="text-center">
                                            <Button
                                                color="info"
                                                onClick={this.add}
                                            >
                                                <MdAdd /> Add New User
                                            </Button>
                                            <Modal
                                                isOpen={this.state.add}
                                                toggle={this.add}
                                                className={this.props.className}
                                                onExit={() => {
                                                    this.resetModalStates()
                                                }}
                                                onClosed={() => {
                                                    this.resetModalStates()
                                                }}
                                            >
                                                <ModalHeader toggle={this.add}>
                                                    Allow my friend to view my keys
                                                </ModalHeader>
                                                <ModalBody>
                                                    <Form onSubmit={this.submitDelegate}>
                                                        <FormText>
                                                            Please note that by allowing this, you will be delegating access to your friend to view your keys. However, they will not be able to make changes to your application.
                                                        </FormText>
                                                        <FormGroup>
                                                            <Label>
                                                                Enter friend’s email address
                                                            </Label>
                                                            <Input
                                                                type="email"
                                                                id="email"
                                                                required
                                                                value={this.state.email}
                                                                onChange={this.onChange}
                                                                invalid={this.state.error ? true : undefined}
                                                                placeholder="Email Address"
                                                            />
                                                            <FormFeedback invalid={this.state.error ? '' : undefined}>
                                                                {this.state.addDelegateMessage}
                                                            </FormFeedback>
                                                        </FormGroup>
                                                        <Button
                                                            disabled={this.state.isAddLoading}
                                                            color={this.state.isAddLoading ? "secondary" : "primary"}
                                                        >
                                                            {this.state.isAddLoading ? (
                                                                <PulseLoader
                                                                    size={10}
                                                                    color="#fff"
                                                                    css={{ margin: `auto` }}
                                                                />
                                                            ) : (
                                                                    `Continue`
                                                                )}
                                                        </Button>
                                                    </Form>
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button
                                                        color="secondary"
                                                        onClick={() => {
                                                            this.add()
                                                            this.resetModalStates()
                                                        }}>
                                                        Close
                                                    </Button>{' '}
                                                </ModalFooter>
                                            </Modal>
                                        </td>
                                    </tr>

                                ) : (
                                        null
                                    )}

                            </tbody>
                        </Table>
                    </Col>
                ) : null}
            </Row>
        )
    }
}

// Production View
export class ViewProductionContent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: this.props.data,
            stage: "production",
            api: [],
            email: "",

            modal: false,
            delete: false,
            add: false,

            isDeleteLoading: false,
            isApiLoading: false,
            isQrLoading: false,
            isAddLoading: false,

            error: false,
            name: user ? user.userData.attributes.name : "",
            selfEmail: user ? user.userData.attributes.email : "",
            username: user ? user.userData.username : "",
            apiList: this.props.apiData,
            apiStr: []

        }
        this.toggle = this.toggle.bind(this)
        this.delete = this.delete.bind(this)
    }

    async componentDidMount() {
        var apis = await handleGetApiDetails()
        this.setState({
            api: apis,
            apiStr: []
        })
    }

    // MODAL - SECRET
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    // MODAL - DELETE
    delete() {
        this.setState({
            delete: !this.state.delete
        });
    }

    handleDelete = async (event) => {
        this.setState({
            isDeleteLoading: true
        })

        let appId = this.state.data.app_id
        let name = !user ? null : user.userData.username
        let _id = this.state.data._id

        await handleProductionDeleteApplication({ appId, name, _id })
            .then((res) => {
                navigate('/account/application/', { state: { notification: "warning", message: "Application has been successfully deleted." } })
            })
            .catch(err => console.log(err))

    }

    arrayRemove = (arr, value) => {

        return arr.filter(function (ele) {
            return ele !== value;
        });

    }

    render() {
        return (
            <Row>
                <Col md={10}>
                    <h1>
                        <Badge color="info">
                            Production
                        </Badge><br />
                        {this.state.data.name}{` `}
                    </h1>
                </Col>
                {/* Edit and Delete */}
                <Col md={2}>

                    <br />
                    <Row>
                        <Button color="danger"
                            onClick={this.delete}>
                            Delete App
                        </Button>
                        <Modal
                            isOpen={this.state.delete}
                            toggle={this.delete}
                            className={this.props.className}
                        >
                            <ModalHeader toggle={this.delete}>Confirm Delete Application</ModalHeader>
                            <ModalBody>
                                Are you sure you want to delete this application?
                            </ModalBody>
                            <ModalFooter>.
                                <Button
                                    color="danger"
                                    onClick={this.handleDelete}
                                    disabled={this.state.isDeleteLoading}
                                >
                                    {this.state.isDeleteLoading ? (
                                        <PulseLoader
                                            size={10}
                                            color="#fff"
                                        />
                                    ) : (
                                            `DELETE`
                                        )}
                                </Button>{' '}
                                <Button
                                    color="primary"
                                    onClick={this.delete}
                                >
                                    Cancel
                                </Button>{' '}
                            </ModalFooter>
                        </Modal>
                    </Row>
                </Col>
                <Col md={12}>
                    &nbsp;
                </Col>
                {/* API and Secret */}
                <Col md={12}>
                    <Table borderless>
                        <tbody>
                            <tr>
                                <td style={{ padding: `1rem` }} colSpan={8}>
                                    <b>API Key</b>
                                </td>
                                <td style={{ textAlign: `left`, padding: `1rem` }} colSpan={1}>
                                    {this.state.data.key}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: `1rem` }} colSpan={8}>
                                    <b>Secret</b>
                                </td>
                                <td style={{ textAlign: `left`, padding: `1rem` }} colSpan={1}>
                                    <Button color="info"
                                        onClick={this.toggle}>
                                        Click here to view Secret
                                    </Button>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: `1rem` }} colSpan={8}>
                                    <b>API(s) Enabled</b>
                                </td>
                                <td>
                                    {this.state.apiStr.length == 0 ? (
                                        this.state.api.map(function (value, index) {
                                            if (value.api_id.includes(',')) {
                                                let multipleIDs = value.api_id.split(',')
                                                for (let x = 0; x < multipleIDs.length; x++) {
                                                    if (this.state.data.api.includes(multipleIDs[x].trim()) && this.state.apiStr.indexOf(value.production_name) === -1) {
                                                        this.state.apiStr.push(value.production_name)
                                                    }
                                                }
                                            } else {
                                                if (this.state.data.api.includes(value.api_id) && this.state.apiStr.indexOf(value.production_name) === -1) {
                                                    this.state.apiStr.push(value.production_name)
                                                }
                                            }
                                        }, this)
                                    ) : ""}

                                    {this.state.apiStr.join(', ')}
                                    {/* {this.state.data.api.includes("cb02e4dd-7cc3-44eb-ba24-65831556c947") || this.state.data.api.includes("1a40a709-290e-458a-a033-ebda072e06a6") ? `eNETS` : null}
                                    {this.state.data.api.length > 1 ? `, ` : ` `}
                                    {this.state.data.api.includes("73b037f5-591c-4ec6-a701-a7ad93fb5962") || this.state.data.api.includes("9c2d1d6d-22ba-42f3-a361-7eb13f6fe076")  ? `NETS QR` : null} */}
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                        <ModalHeader toggle={this.toggle}>Secret</ModalHeader>
                        <ModalBody>
                            {this.state.data.secret}
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                onClick={event => {
                                    this.toggle()
                                }}>
                                Close
                            </Button>{' '}
                        </ModalFooter>
                    </Modal>
                </Col>
            </Row>
        )
    }
}

// Delegate Container
export class DelegateContainer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            modal: false,
            delegateObj: '',
            userIndex: ''
        }

        this.modal = this.modal.bind(this)
    }

    modal() {
        this.setState({
            modal: !this.state.modal
        })
    }

    handleUserSelected = (data, index ) => {
        this.setState({
            delegateObj: data,
            userIndex: index
        }, () => {
            this.modal();
        })
    }

    removeDelegate = async (delegateData, index  ) => {
        this.setState({
            loading: true
        })
        await handleDeleteApplicationDelegate(delegateData)
            .then((res) => {
                this.props.items.splice(index, 1)
                this.setState({
                    loading: false,
                    modal: false
                }, () => {
                    this.props.onSuccess();
                })
            })
            .catch((err) => {
                this.setState({
                    loading: false
                })
            })
    }


    render() {
        if (!this.props.items || this.props.items.length === 0) {
            return (
                <tr  >
                    <td colSpan={3} className="text-center">
                        No Application delegates found...
                        <br />

                    </td>
                </tr>
            )
        } else {
            return (
                <>
                {
                    this.props.items.map((value, index) => {

                        let ddate = new Date(value.date_added)
                        ddate = ddate.toString()

                        return (
                            <tr key={index}>
                                <td style={{ padding: `1rem` }}>
                                    {ddate}
                                </td>
                                <td style={{ padding: `1rem` }}>
                                    {value.email}
                                </td>
                                <td style={{ padding: `1rem` }}>
                                    {(this.props.stage !== 'delegated') ? (
                                        <Button
                                            key={index}
                                            color="danger"
                                            block
                                            onClick={() => this.handleUserSelected(value, index)}>
                                            Remove User
                                            </Button>
                                    ) : (
                                            ``
                                        )}
                                </td>
                            </tr>
                        )
                    })
                }
                <Modal isOpen={this.state.modal} toggle={this.modal} className={this.props.className}>
                    <ModalHeader toggle={this.modal}>Confirm Delete Application Delegate</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this User Delegation?
                        </ModalBody>
                    <ModalFooter>.
                            <Button
                            color="danger"
                            onClick={() => { this.removeDelegate(this.state.delegateObj, this.state.userIndex) }}
                            disabled={this.state.loading}
                        >
                            {this.state.loading ? (
                                <PulseLoader
                                    size={10}
                                    color="#fff"
                                />
                            ) : (
                                    `DELETE`
                                )}
                        </Button>{' '}
                        <Button
                            color="primary"
                            onClick={this.modal}
                        >
                            Cancel
                            </Button>{' '}
                    </ModalFooter>
                </Modal>
                </>
            )
        }
    }
}

// Delegate User List Loop
export const UserLoop = (props) => {

    return props.items.map(function (value, index) {

        let ddate = new Date(value.date_added)
        ddate = ddate.toString()

        return (
            <tr key={index}>
                <td style={{ padding: `1rem` }}>
                    {ddate}
                </td>
                <td style={{ padding: `1rem` }}>
                    {value.email}
                </td>
                <td style={{ padding: `1rem` }}>
                    <Button
                        key={index}
                        color="danger"
                        block
                        onClick={async () => {
                            await handleDeleteApplicationDelegate( value )
                                .then((res) => {

                                })
                        }
                        }>
                        Remove User
                    </Button>
                </td>
            </tr>
        )
    })
}